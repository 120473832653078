@import "../../style/spacing.scss";
@import "../../style/typography.scss";
@import "../../style/colors.scss";
@import "../../style/mixins.scss";

section.search {
  display: grid;
  grid-template-columns: 1.5fr 5fr;
  column-gap: $spacing-s;
  padding-bottom: $spacing-l;

  @include medium-screen {
    grid-template-columns: 1fr 2fr;
  }

  @include small-screen {
    grid-template-columns: 1fr;
  }
}
.searchAndButtonWrapper {
  display: flex;
}

.noErrorField {
  input {
    max-height: 56px;
  }
  p {
    display: none;
  }
}

.searchButton {
  margin-top: $spacing-s;
}

.verticallyAlign {
  margin-left: $spacing-m;
  display: flex;
  align-items: center;
}

.label {
  margin-bottom: $spacing-s;
  span {
    margin-right: $spacing-s;
  }

  .labelTitle {
    font-weight: $fontWeightLight;
  }
}

.results {
  border-top: 1px solid $borderColorLight;
  .item {
    border-bottom: 1px solid $borderColorLight;
    padding: $spacing-m;
    .section {
      display: grid;
      grid-template-columns: 2fr 1fr 2fr 1fr;

      .itemBalance {
        grid-column: 4;
      }
      @include medium-screen {
        grid-template-columns: 1fr 1fr;
      }
      @include small-screen {
        grid-template-columns: 1fr;
      }
    }

    .scrapButton {
      display: flex;
      justify-content: flex-end;
      @include small-screen {
        justify-content: center;
      }
    }
  }
}

.deviation {
  margin-left: $spacing-s;
  color: $red;
}
