@import "../../../../style/colors.scss";
@import "../../../../style/mixins.scss";
@import "../../../../style/sizes.scss";
@import "../../../../style/spacing.scss";
@import "../../../../style/typography.scss";

%statusBar {
  position: absolute;
  content: "";
  width: 6px;
  left: 0;
  height: 100%;
  background-color: $green;
}

%statusText {
  position: absolute;
  top: $spacing-s;
  right: $spacing-s;
  border-width: 1px;
  border-style: solid;
  font-size: $fontSizeSmall;
  padding: 2px $spacing-xs;
  border-radius: $borderRadiusSmall;
  margin: 0;
  text-transform: uppercase;
}

.contentType {
  margin-bottom: $spacing-xl;

  .title {
    @extend %sectionTitle;
    margin-bottom: $spacing-s;
  }
}

.itemContainer {
  display: grid;
  grid-template-columns: 4rem 1fr;
}

.contentItem {
  border: 1px solid $borderColorLight;
  margin-bottom: $spacing-xs;
  border-radius: $borderRadiusSmall;
  padding: $spacing-m;
  position: relative;

  display: grid;
  grid-template-columns: minmax(0, 3fr) minmax(25rem, 2fr);
  gap: $spacing-m;
  align-items: flex-start;

  grid-template-areas:
    "info files files"
    "address files files"
    "buttons buttons contentNumber";

  &:nth-child(odd) {
    background-color: $contentItemBgColor;
  }

  &.ready::before {
    @extend %statusBar;
    background-color: $green;
  }
  &.draft::before {
    @extend %statusBar;
    background-color: lighten($yellow, 20%);
  }

  .markDone {
    @extend %statusText;
    background-color: $green;
    color: darken($green, 10%);
    border-color: $green;
    background: lighten($green, 40%);
  }
  .markDraft {
    @extend %statusText;
    background-color: $yellow;
    color: darken($yellow, 10%);
    border-color: $yellow;
    background: lighten($yellow, 40%);
  }

  .status20 {
    @extend %statusText;
    background-color: $grey;
    color: darken($grey, 10%);
    border-color: $grey;
    background: lighten($grey, 40%);
  }
  .status80 {
    @extend %statusText;
    background-color: $orange;
    color: darken($orange, 10%);
    border-color: $orange;
    background: lighten($orange, 25%);
  }
  .status90 {
    @extend %statusText;
    background-color: $greenDark;
    color: darken($greenDark, 10%);
    border-color: $greenDark;
    background: lighten($greenDark, 40%);
  }
  .status99 {
    @extend %statusText;
    background-color: $red;
    color: darken($red, 10%);
    border-color: $red;
    background: lighten($red, 40%);
  }
}

.itemInfo {
  &.grid {
    grid-area: info;
    display: grid;
    grid-template-columns: minmax(0, 1fr) 13rem; //13rem for counter width
    align-items: flex-start;
    gap: $spacing-m;
  }

  p {
    margin: 0;
  }
}

.addressContainer {
  grid-area: address;
}

.statusCommentShown {
  grid-template-areas:
    "info statusComment statusComment"
    "address files files"
    "buttons buttons contentNumber" !important;
}
.statusComment {
  grid-area: statusComment;
  border-left: 1px solid #eee;
  height: 100%;
  padding-left: $spacing-m;
  padding-top: $spacing-xl;
}
.fileUpload {
  grid-area: files;
  border-left: 1px solid #eee;
  height: 100%;
  padding-left: $spacing-m;
  &.margin {
    padding-top: $spacing-s;
  }
}

.actionButtons {
  grid-area: buttons;
  display: flex;

  .button {
    margin-right: $spacing-l;
    color: $affordance;

    &:not(:first-child) {
      margin-left: $spacing-l;
    }
  }
}
.contentNumber {
  grid-area: contentNumber;
  font-size: $fontSizeSmall;
  color: $lightGrey;
}
.contentTitle {
  font-size: $fontSizeMedium;
}

.rowTitle {
  font-weight: $fontWeightLight;
  margin-bottom: 0;
}

.text {
  display: inline-grid;
}

.marginBottom,
p.marginBottom {
  margin-bottom: $spacing-m;
}

@include medium-screen {
  .contentItem {
    grid-template-areas:
      "info files"
      "address files"
      "buttons buttons ";
  }
}
@include small-screen {
  .contentItem {
    padding-top: $spacing-xl;

    grid-template-columns: minmax(0, 1fr);
    grid-template-areas:
      "info"
      "address"
      "files"
      "buttons ";

    .markDone,
    .markDraft {
      right: auto;
      border: 0;
      left: 6px;
      top: 0px;
      padding: $spacing-xs $spacing-s;
    }
  }

  .fileUpload {
    padding: 0;
    border: 0;
    height: auto;
  }
}
