@import "../../../style/spacing.scss";

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: $spacing-xl;
}

.orderOverviewModal {
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    height: 75vh;
  }
}
