@import "../../../style/colors.scss";
@import "../../../style/spacing.scss";

.dropmenu {
  position: relative;

  .button {
    color: $lightGrey;
    background: none;
    width: 28px;
    height: 28px;
    border: none;
    outline: none;

    padding: 4px;
    margin: 4px;

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }

  .menu {
    min-width: 140px;
    position: absolute;
    right: 0;
    border: 1px solid rgba(0, 0, 0, 0.05);
    background: white;
    z-index: 2;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);

    li {
      padding: $spacing-s;

      &:hover {
        background: rgba(0, 0, 0, 0.05);
        cursor: pointer;
      }
    }
  }
}
