@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";
@import "../../../style/sizes.scss";
@import "../../../style/spacing.scss";

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    width: 50%;
    margin-right: 1rem;
    border-style: solid;
    border-width: 1px;
    border-color: $primary;
    border-radius: $borderRadius;
    padding: $spacing-m;
    color: currentColor;
    &::placeholder {
      opacity: 1;
    }
    &:focus::placeholder {
      opacity: 1;
    }

    &:hover {
      border-color: $primaryDark;
    }
    &:focus,
    &:active,
    &:focus-within {
      outline-width: 1px;
      outline-color: $primaryDark;
    }
  }
}

.views {
  display: flex;
  justify-content: flex-end;
  margin-bottom: $spacing-s;

  .active {
    color: $primary;
  }
}

.toolBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activeFilters {
  margin: 1rem 0;
}

.badge {
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 0.5rem 1rem;
  margin-right: 1rem;
  display: inline-flex;
  align-items: center;

  &:last-child {
    margin-right: 0;
  }
  button {
    background: none;
    border: none;
    outline: none;
    margin-left: 1rem;
    padding: 0 0.5rem;
  }
}

.productListGrid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: $spacing-l;
  margin: $spacing-xl 0;

  .listItem {
    &:hover {
      @include boxShadow();
    }
  }
  .productLink {
    color: currentColor;
    display: block;
    padding: $spacing-m;
    border-radius: $borderRadiusSmall;
    &:hover {
      text-decoration: none;
      color: $affordance;
    }
  }

  @include medium-screen {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include small-screen {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: $spacing-s;
    .productLink {
      padding: $spacing-s;
    }
  }
}

.productList {
  border-top: 1px solid $borderColorLight;
  padding: $spacing-s;

  &:last-child {
    border-bottom: 1px solid $borderColorLight;
  }

  .group {
    display: flex;
  }

  .greyText {
    color: $lightGrey;
  }

  .upperRow {
    display: grid;
    grid-template-columns: 3fr 1fr;
  }

  .lowerRow {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
