@import "../../../style/colors.scss";
@import "../../../style/spacing.scss";
@import "../../../style/typography.scss";

.listContainer {
  max-width: 500px; //TBD
}
.header {
  display: flex;
  margin-bottom: $spacing-l;
  .contentTitle {
    flex: 1;
    @extend %sectionTitle;
    margin-bottom: 0;
  }
}

.addNewBtn {
  .addNewText {
    margin-left: $spacing-s;
  }
}

.list {
  border: 1px solid $borderColorLight;
  max-height: 600px;
  overflow-y: auto;
  margin-bottom: $spacing-l;
  .listItem {
    padding: $spacing-s;

    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    grid-template-areas:
      "title actions"
      "content actions";

    &:not(:last-child) {
      border-bottom: 1px solid $borderColorLight;
    }

    &:nth-child(odd) {
      background-color: $contentItemBgColor;
    }

    .itemTitle {
      grid-area: title;
      margin-bottom: $spacing-xs;
      font-weight: $fontWeightMedium;
    }

    .itemContent {
      grid-area: content;
      span {
        display: block;
      }
    }

    .actions {
      grid-area: actions;
      display: flex;
      padding-top: $spacing-xs;
      .edit {
        margin: 0 $spacing-m;
      }
      .delete {
        margin: 0 $spacing-m;
      }
    }
  }
}
