@import "../../../style/spacing.scss";
@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";
@import "../../../style/typography.scss";

.accessories {
  .accessory {
    display: flex;

    margin: $spacing-s 0 $spacing-s 0;

    .details {
      border-top: 1px solid $borderColorLight;
      padding: $spacing-xs 0;
      width: 100%;
    }

    .head {
      display: flex;
      width: 100%;
      font-size: $fontSizeMedium;
      margin-bottom: $spacing-xs;
    }

    .row {
      display: flex;
      width: 100%;
    }
  }

  .left {
    flex: 1;
  }

  .right {
    text-align: right;
  }

  .image {
    margin-right: $spacing-m;

    figure {
      height: 80px !important;
      width: 80px !important;
    }
  }
}
