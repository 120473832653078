@import "../../../style/mixins.scss";
@import "../../../style/spacing.scss";

.list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: $spacing-xl;

  .logo {
    width: 15rem;
    margin: $spacing-m;
  }

  .logoNoWidth {
    margin: $spacing-m;
  }
  .specialLogo {
    height: 12rem;
    margin: $spacing-m;
  }

  @include small-screen {
    display: grid;
    gap: $spacing-xl $spacing-xxl;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-auto-rows: minmax(0, 5rem);
    width: fit-content;

    .logo {
      max-height: 100%;
      max-width: 100%;
      margin: 0;

      &:last-child:nth-child(odd) {
        height: 100%;
        width: auto;
        grid-column: span 2;
        margin: 0 auto;
      }
    }
  }
}
