@import "../../../../style/spacing.scss";

.quantityText {
  text-align: center;
}

.assemblyCheckbox {
  margin-top: $spacing-m;
}

.price {
  padding-top: $spacing-s;
}
